import Router from "next/router";
import React, { cloneElement, isValidElement, useEffect } from "react";
import { useUserStore } from "stores/user/useUserStore";

const PrivateRoute = ({ children }) => {
  const { user } = useUserStore();
  // * Redirect to home if not logged in2
  useEffect(() => {
    if (!user) {
      Router.replace("/login");
    }
  }, [user]);

  return React.Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child);
    }

    return child;
  });
};

export default React.memo(PrivateRoute);
