import { LOGO_ISOTIPO } from "@constants/global";
import ImageLogoFontsDark from "@public/svg/logo/logo-fonts-dark.svg";
import ImageLogoFontsLight from "@public/svg/logo/logo-fonts-light.svg";
import Icon from "components/Icon";
import { useTheme } from "next-themes";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { PRIVATE_ROUTES } from "routes/routes";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";

export default function SidebarHeader() {
  const { isExpanded, toggleSidebar } = useDesktopSidebarStore();
  const { theme } = useTheme();
  const [isDesktopLogo, setIsDesktopLogo] = useState(true);
  const router = useRouter();

  function handleRedirect(path: string) {
    router.push(path);
  }

  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        setIsDesktopLogo(false);
      }, 200);
    }
    if (isExpanded) {
      setIsDesktopLogo(true);
    }
  }, [isExpanded]);

  return (
    <div className="relative flex h-[62px] w-full items-center border-b-[1px] border-gray-400 bg-gray-50 dark:border-gray-600 dark:bg-gray-900 md:h-sidebar-header">
      <picture
        style={{
          display: "grid",
          alignContent: "center",
          width: "auto",
          minWidth: isDesktopLogo ? "34px" : "40px",
          marginLeft: isDesktopLogo ? "24px" : "22px",
        }}
      >
        <Image
          className="cursor-pointer"
          src={LOGO_ISOTIPO}
          width={40}
          height={40}
          onClick={() => handleRedirect(PRIVATE_ROUTES.profile.path)}
          alt="logo"
        />
      </picture>
      {isDesktopLogo && (
        <picture
          style={{
            position: "relative",
            display: "grid",
            alignContent: "center",
            width: "100%",
            height: "40px",
            minWidth: "130px",
            marginLeft: "8px",
          }}
        >
          <Image
            className="absolute top-2 cursor-pointer"
            src={theme === "dark" ? ImageLogoFontsDark : ImageLogoFontsLight}
            width={
              theme === "dark"
                ? ImageLogoFontsDark.width
                : ImageLogoFontsLight.width
            }
            height={
              theme === "dark"
                ? ImageLogoFontsDark.height
                : ImageLogoFontsLight.height
            }
            onClick={() => handleRedirect(PRIVATE_ROUTES.profile.path)}
            alt="logo fonts"
          />
        </picture>
      )}
      <div
        className={`absolute right-0 z-10 ${isDesktopLogo ? "" : "translate-x-4"}`}
      >
        <button
          onClick={toggleSidebar}
          className={`grid h-8 w-8 place-items-center justify-center rounded-full hover:text-current-500 dark:bg-gray-800 dark:hover:bg-gray-800 ${!isExpanded ? "bg-gray-200" : ""}`}
        >
          <Icon
            icon={
              !isDesktopLogo
                ? ["far", "chevron-right"]
                : ["far", "chevron-left"]
            }
            style={{
              fontSize: 16,
            }}
          />
        </button>
      </div>
    </div>
  );
}
