import Avatar from "boring-avatars";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useUserStore } from "stores/user/useUserStore";
import { guardCheckUserRole } from "../../../utils/guards";

import { AVATAR_COLORS, DEFAULT_AVATAR } from "@constants/global";
import { PRIVATE_ROUTES } from "routes/routes";
import SVG from "../../SVG";
import { ISidebarMenu } from "./Sidebar";
import {
  IMenuList,
  IMenuListItem,
  LAYOUT_STORED_MENU,
  LAYOUT_STORED_SUBMENU,
  menuItemByPath,
} from "./utils";

interface IMainMenuStatic extends ISidebarMenu {
  isMenuOpen: boolean;
  lists: Array<IMenuList>;
  onItemClick?: (item: IMenuListItem) => void;
}

const MainMenuStatic = (props: IMainMenuStatic) => {
  const {
    isMenuOpen,
    lists,
    status,
    onItemClick: onItemClickProp,
    // motion,
  } = props;

  const router = useRouter();
  const { t } = useTranslation("common");
  const { user } = useUserStore();

  const [active, setActive] = useState<string>("");

  useEffect(() => {
    let activeMainMenuItemName = menuItemByPath(lists, router.route)?.name;

    // * If there's no main menu item name, set it to config if the user is at one submenu route
    if (!activeMainMenuItemName) {
      activeMainMenuItemName = localStorage.getItem(LAYOUT_STORED_SUBMENU)
        ? t("Configuración")
        : "";
    }

    setActive(activeMainMenuItemName);
  }, []);

  const onItemClick = (item: IMenuListItem) => {
    setActive(item.name);
    localStorage.removeItem(LAYOUT_STORED_SUBMENU);
    localStorage.setItem(LAYOUT_STORED_MENU, JSON.stringify(item));
    if (onItemClickProp) onItemClickProp(item);
  };

  return (
    <div
      className="flex h-full w-full flex-col overflow-y-auto overflow-x-visible"
      style={{ pointerEvents: "auto" }}
    >
      <div
        className="mt-10 flex h-16 w-full items-center"
        style={{
          width: status === "moving" ? "20%" : "100%",
        }}
      >
        <div
          className="flex items-center justify-center"
          style={{ width: status === "opened" ? "20%" : "100%" }}
        >
          <div className="cursor-pointer items-center p-2">
            <div onClick={() => router.push(PRIVATE_ROUTES.profile.path)}>
              {user?.profileImage === DEFAULT_AVATAR ? (
                <Avatar
                  size={55}
                  name={`${user?.name} ${user?.lastname}`}
                  variant="beam"
                  colors={AVATAR_COLORS}
                />
              ) : (
                <div
                  className="h-14 w-14 rounded-full border-2 border-current-500 bg-gray-900 dark:bg-white"
                  style={{
                    backgroundImage: `url(${user?.profileImage})`,
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {status === "opened" && (
          <div className="flex-1 pl-2">
            <div className="animate__animated animate__fadeIn">
              <div className="ml-3">
                {user?.name} {user?.lastname}
              </div>
              <span className="text-xs"> {user?.role?.name}</span>
              <span className="text-xs text-current-500">
                {user?.systemRole}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="mt-5 flex-1 flex-col overflow-y-auto overflow-x-visible py-5">
        {lists.map((list) => (
          <div key={list.header}>
            {list.header && (
              <div className="flex h-3 w-full items-center py-3 pl-4">
                {isMenuOpen ? (
                  <div className="animate__animated animate__fadeIn text-center text-gray-400">
                    {list.header}
                  </div>
                ) : (
                  <hr className="w-fullborder-gray-500 w-full" />
                )}
              </div>
            )}

            {list.items.map((item) => {
              if (!guardCheckUserRole(item.route?.roleGuards, user?.systemRole))
                return null;

              return (
                <div
                  key={item.name}
                  className={`flex h-16 w-full cursor-pointer items-center hover:bg-gray-100 dark:hover:bg-gray-700 ${
                    active === item.name ? "bg-gray-100 dark:bg-gray-700" : ""
                  }`}
                  style={{
                    width: status === "moving" ? "20%" : "100%",
                  }}
                  onClick={() => onItemClick(item)}
                >
                  <div
                    className="flex items-center justify-center"
                    style={{ width: status === "opened" ? "20%" : "100%" }}
                  >
                    <SVG src={`/svg/${item.icon}.svg`} width={30} height={30} />
                  </div>

                  {status === "opened" && (
                    <div className="animate__animated animate__fadeIn flex-1 pl-2">
                      {item.name}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainMenuStatic;
