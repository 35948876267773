import { gql } from "@apollo/client";

export interface IGetPublicAppreciationDistributeInput {
  limit?: number;
  offset?: number;
}

const GET_APPRECIATIONS_ENABLE_FOR_PRESELECTION = gql`
  query appreciationsEnableForPreselection {
    appreciationsEnableForPreselection {
      id
      name
      questions
      roles {
        id
        name
      }
    }
  }
`;

const GET_PUBLIC_APPRECIATIONS = gql`
  query publicAppreciations(
    $getPublicAppreciationDistributeInput: GetPublicAppreciationDistributeInput!
  ) {
    publicAppreciations(
      getPublicAppreciationDistributeInput: $getPublicAppreciationDistributeInput
    ) {
      id
      token
      createdAt
      userName
      completedPercent
      userGeneralMaturityLevel
      seniorityByUserGeneralMaturityLevel
      completed
      appreciation {
        name
      }
      userEmail
      startedAt
      timeToAnswer
    }
  }
`;

export interface IGetUserPublicAppreciationInput {
  token: string;
}

const GET_USER_PUBLIC_APPRECIATION = gql`
  query userPublicAppreciation(
    $getUserPublicAppreciationInput: GetUserPublicAppreciationInput!
  ) {
    userPublicAppreciation(
      getUserPublicAppreciationInput: $getUserPublicAppreciationInput
    ) {
      completedPercent
      createdAt
      userEmail
      id
      activeQuestionIndex
      completed
      isAvailable
      startDate
      startedAt
      endDate
      timeToAnswer
      appreciation {
        id
        name
        timeToAnswer
        description
        dimensions {
          id
          name
        }
      }
      started
      completed
      userGeneralMaturityLevel
      userMaturityLevelByDimension {
        dimensionName
        maturityname
        percent
      }
      questions {
        id
        answered
        answeredAt
        originalId
        name
        dimension
        isCorrect
        correctOptions
        options {
          originalId
          name
          checked
        }
      }
      role
    }
  }
`;

const GET_USER_PUBLIC_APPRECIATION_PAGINATED = gql`
  query PublicAppreciationsPaginated(
    $getPublicAppreciationDistributeInput: GetPublicAppreciationDistributeInput!
  ) {
    publicAppreciationsPaginated(
      getPublicAppreciationDistributeInput: $getPublicAppreciationDistributeInput
    ) {
      publicAppreciations {
        id
        token
        createdAt
        userName
        completedPercent
        userGeneralMaturityLevel
        seniorityByUserGeneralMaturityLevel
        completed
        appreciation {
          name
        }
        userEmail
        startedAt
        timeToAnswer
      }
      total
    }
  }
`;

export default {
  GET_PUBLIC_APPRECIATIONS,
  GET_APPRECIATIONS_ENABLE_FOR_PRESELECTION,
  GET_USER_PUBLIC_APPRECIATION,
  GET_USER_PUBLIC_APPRECIATION_PAGINATED,
};
