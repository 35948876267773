import { gql } from "@apollo/client";
import { IAppreciationDistributeQuestionOption } from "types/Appreciation/AppreciationDistributeQuestionOption.type";

export interface ICreatePublicAppreciationDistributeInput {
  email: string;
  startDate: Date;
  endDate: Date;
  appreciation: string[];
  role: string;
  timeToAnswer?: number;
}

const CREATE_PUBLIC_APPRECIATION_DISTRIBUTE = gql`
  mutation createPublicAppreciationDistribute(
    $createPublicAppreciationDistributeInput: CreatePublicAppreciationDistributeInput!
  ) {
    createPublicAppreciationDistribute(
      createPublicAppreciationDistributeInput: $createPublicAppreciationDistributeInput
    )
  }
`;

const START_PUBLIC_APPRECIATION_DISTRIBUTE = gql`
  mutation startPublicAppreciationDistribute($id: String!) {
    startPublicAppreciationDistribute(id: $id) {
      id
      started
    }
  }
`;

export interface ISaveAppreciationDistributeQuestionType {
  id: string;
  name: string;
  options: IAppreciationDistributeQuestionOption[];
}
export interface ISavePublicAppreciationDistributeInput {
  id: string;
  question: ISaveAppreciationDistributeQuestionType;
  activeQuestionIndex: number;
}

const SAVE_PUBLIC_APPRECIATION_DISTRIBUTE = gql`
  mutation savePublicAppreciationDistribute(
    $savePublicAppreciationDistributeInput: SavePublicAppreciationDistributeInput!
  ) {
    savePublicAppreciationDistribute(
      savePublicAppreciationDistributeInput: $savePublicAppreciationDistributeInput
    ) {
      id
      completed
    }
  }
`;

const DELETE_PUBLIC_APPRECIATION_DISTRIBUTE = gql`
  mutation DeletePublicAppreciationDistribute(
    $deletePublicAppreciationDistributeId: String!
  ) {
    deletePublicAppreciationDistribute(
      id: $deletePublicAppreciationDistributeId
    )
  }
`;

export default {
  CREATE_PUBLIC_APPRECIATION_DISTRIBUTE,
  START_PUBLIC_APPRECIATION_DISTRIBUTE,
  SAVE_PUBLIC_APPRECIATION_DISTRIBUTE,
  DELETE_PUBLIC_APPRECIATION_DISTRIBUTE,
};
