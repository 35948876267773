import { gql } from "@apollo/client";
import { IBase } from "types/Base";
import {
  EFormResponseStatusType,
  Form,
  FormsPaginated,
  GetFormResponse,
  GetFormResponseByFormId,
  IFormQuestion,
} from "types/Form.type";
import { IUser } from "types/User.types";

export interface GetFormResponsePagination {
  getFormResponsePagination: GetFormResponse;
}

const MYFORMS = gql`
  query getFormResponsePagination(
    $offset: Int
    $limit: Int
    $name: String
    $status: EFormResponseStatusType
    $createdBy: Boolean
  ) {
    getFormResponsePagination(
      offset: $offset
      limit: $limit
      name: $name
      status: $status
      createdBy: $createdBy
    ) {
      data {
        id
        user {
          email
        }
        form {
          id
          name
          questions {
            id
            type
          }
          createdBy {
            id
            name
            lastname
            email
            profileImage
          }
        }
        status
      }
      total
      totalPages
    }
  }
`;

const FORM_RESPONSES = gql`
  query getFormResponsePagination(
    $offset: Int
    $limit: Int
    $name: String
    $status: EFormResponseStatusType
    $createdBy: Boolean
    $formId: String
  ) {
    getFormResponsePagination(
      offset: $offset
      limit: $limit
      name: $name
      status: $status
      createdBy: $createdBy
      formId: $formId
    ) {
      data {
        id
        email
        form {
          id
          name
          questions {
            id
            type
          }
        }
        status
        user {
          email
        }
        sentiment
        sentimentScore
      }
      total
      totalPages
    }
  }
`;

export interface IFormResponse extends IBase {
  user: IUser;
  form: Form;
  answers: IFormQuestion[];
  status: EFormResponseStatusType;
  hidden?: boolean;
  creator?: string;
  email?: string;
  sentiment?: string;
  sentimentScore?: number;
}

const FORM = gql`
  query Form($id: String!) {
    form(id: $id) {
      id
      name
      description
      hidden
      enableSentimentAnalysis
      questions {
        id
        name
        type
        required
        order
        details {
          startText
          endText
        }
        formQuestionOptions {
          id
          name
        }
      }
    }
  }
`;

export interface IGetFormsPaginatedInput {
  name: string;
  limit: number;
  offset: number;
}

export interface IFormsPaginatedResponse {
  formsPaginated: FormsPaginated;
}

const FORMS = gql`
  query FormsPaginated($getFormsPaginatedInput: GetFormsPaginatedInput!) {
    formsPaginated(getFormsPaginatedInput: $getFormsPaginatedInput) {
      data {
        id
        createdAt
        name
        description
        hidden
        enableSentimentAnalysis
        createdBy {
          id
          name
          lastname
          email
          profileImage
        }
        questions {
          id
        }
        isQuickie
      }
      total
      totalPages
    }
  }
`;

export interface IGetFormResponseByFormIdResponse {
  getFormResponseByFormId: GetFormResponseByFormId;
}

const GET_FORM_RESPONSES = gql`
  query GetFormResponseByFormId($id: ID!) {
    getFormResponseByFormId(id: $id) {
      answers
      questions {
        id
        type
        order
        question
        details {
          startText
          endText
        }
        answers {
          answer
          userName
          userLastName
          userProfileImage
          userEmail
          userId
        }
        options {
          id
          name
          quantity
        }
      }
    }
  }
`;

const GET_FORM_RESPONSES_FILE = gql`
  query Query($getCsvFormResponseId: ID!) {
    getCsvFormResponse(id: $getCsvFormResponseId)
  }
`;

const GET_FORM_RESPONSE_BY_ID = gql`
  query FormResponseById($formResponseByIdId: String!) {
    formResponseById(id: $formResponseByIdId) {
      form {
        id
        name
        description
        hidden
        enableSentimentAnalysis
        questions {
          id
          name
          type
          required
          order
          details {
            startText
            endText
          }
          formQuestionOptions {
            id
            name
          }
        }
      }
      status
    }
  }
`;

export default {
  MYFORMS,
  FORMS,
  FORM,
  GET_FORM_RESPONSES,
  GET_FORM_RESPONSES_FILE,
  GET_FORM_RESPONSE_BY_ID,
  FORM_RESPONSES,
};
