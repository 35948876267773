import Image from "next/image";
import { useMemo } from "react";

interface ISVGProps {
  src: string;
  width?: number;
  height?: number;
  onClick?: () => void;
  className?: string;
  alt?: string;
}
const SVG = (props: ISVGProps) => {
  const { width, height, src, onClick, className, alt } = props;

  return useMemo(
    () => (
      <Image
        {...{ src, onClick }}
        className={`${onClick ? "cursor-pointer" : ""} ${className}`}
        width={width || 100}
        height={height || 100}
        alt={alt || "SVG"}
      />
    ),
    []
  );
};

// !!width ? (typeof width === "number" ? `${width}px` : width) : 100
export default SVG;
