import { FC, ReactNode } from "react";

interface PortalLayoutProps {
  children: ReactNode;
}

const PortalLayout: FC<PortalLayoutProps> = ({ children }) => (
  <div className="h-screen min-w-full">
    <div
      style={{
        minHeight: "calc(100vh - 100px)",
      }}
    >
      <div className="animate__animated" id="layoutMainContent">
        <div className="h-full min-h-full">{children}</div>
      </div>
    </div>
  </div>
);

export default PortalLayout;
