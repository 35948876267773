import { gql } from "@apollo/client";
import { IRecognition } from "types/Recognition.type";

export interface IRecognitionsResponse {
  getRecognitionCount: IRecognition[];
}

const GET_RECOGNITIONS = gql`
  query getRecognitionCount {
    getRecognitionCount {
      avatar
      corporateValueTitle
      corporateValueId
      quantityReceived
      quantitySend
    }
  }
`;

export default { GET_RECOGNITIONS };
