import PublicNavBar from "components/NavBar/PublicNavBar";
import { FC, ReactNode } from "react";

interface PublicWithHeaderProps {
  children: ReactNode;
}

const PublicWithHeader: FC<PublicWithHeaderProps> = ({ children }) => (
  <div className="flex h-screen flex-col">
    <PublicNavBar />

    <div className="container mx-auto p-6 md:p-3">{children}</div>
  </div>
);

export default PublicWithHeader;
