import { IMenuList } from "components/Layout/ManagerProLayout/utils";
import { EPrivateRouteType } from "components/SideMenu/side-menu-routes";
import { useIsScreenMd } from "hooks/useIsScreenMd";
import { useRouter } from "next/router";
import { useState } from "react";
import { IRoute } from "routes/types";
import { useMobileSidebarStore } from "stores/sidebar/useMobileSidebarStore";
import { useUserStore } from "stores/user/useUserStore";
import { guardCheckUserRole } from "utils/guards";
import MenuDropdownItem from "../ui/MenuDropdownItem";
import MenuItem from "../ui/MenuItem";
import MainMenuList from "./utils/menuList";

export default function SidebarContent() {
  const router = useRouter();
  const [isHoverItem, setIsHoverItem] = useState(false);
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null);
  const { user } = useUserStore();
  const isScreenMd = useIsScreenMd();
  const { toggleBurgerSidebar } = useMobileSidebarStore();

  const menuItems: IMenuList[] = MainMenuList();

  const handleRedirect = (path: string) => {
    router.push(path);
    if (!isScreenMd) {
      toggleBurgerSidebar();
    }
  };

  const checkSubRoutesRoleGuard = (routes: IRoute[]) => {
    if (!Array.isArray(routes)) {
      console.error("Expected routes to be an array but got:", routes);
      return true; // Si no es un array, siempre es válido por defecto
    }

    let isValid = true;
    for (const route of routes) {
      if (route?.jobyfineRecruiter && route?.jobyfineRequester) {
        // * validate both
        if (user?.jobyfineRecruiter || user?.jobyfineRequester) {
          isValid = false;
          break;
        }
      }

      // * validate separately
      if (route?.jobyfineRecruiter && user?.jobyfineRecruiter) {
        isValid = false;
        break;
      }

      // * validate separately
      if (route?.jobyfineRequester && user?.jobyfineRequester) {
        isValid = false;
        break;
      }

      if (
        guardCheckUserRole(route.roleGuards || [], user?.systemRole, user?.isi)
      ) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const evaluateSubRoutesIncludesPath = (
    pathname: string,
    item: IMenuList
  ): boolean => {
    return (
      (item.children &&
        item.children.some(
          (route) => route.route && pathname.includes(route.route.path)
        )) ||
      false
    );
  };

  return (
    <div className="" id="sidebar-body">
      {menuItems.map((item, index) => {
        if (item.type === EPrivateRouteType.ITEM) {
          return (
            <MenuItem
              key={item.name}
              item={item}
              onClick={handleRedirect}
              checkSubRoutesRoleGuard={checkSubRoutesRoleGuard}
              setIsHoverItem={setIsHoverItem}
            />
          );
        }
        if (item.type === EPrivateRouteType.DROPDOWN) {
          return (
            <MenuDropdownItem
              key={item.name}
              item={item}
              onRedirect={handleRedirect}
              checkSubRoutesRoleGuard={checkSubRoutesRoleGuard}
              evaluateSubRoutesIncludesPath={evaluateSubRoutesIncludesPath}
              isHovered={hoveredItemIndex === index && !isHoverItem}
              onHover={() => setHoveredItemIndex(index)}
              setIsHoverItem={setIsHoverItem}
            />
          );
        }
        return null;
      })}
    </div>
  );
}
