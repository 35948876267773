import { gql } from "@apollo/client";
import { IAppreciationDistribute } from "types/Appreciation/AppreciationDistribute.type";
import { IRole } from "types/Role.type";
import { EUserSeniority, GenderEnum, IUserOnboarding } from "types/User.types";
import { IUserInvitation } from "types/UserInvitation.type";

export interface ILoginInput {
  email: string;
  password: string;
}

export interface ILoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IGetUserActiveCareerInput {
  user: string;
}
export interface IEducation {
  dateEnd: string;
  dateStart: string;
  description: string;
  title: string;
}

// Definición de la estructura para los idiomas
export interface ILanguage {
  level: string;
  name: string;
}

// Definición de la estructura para la experiencia laboral
export interface IWorkExperience {
  date: string;
  description: string;
  title: string;
  company: string;
}

// Definición de la estructura para la información del CV del usuario
export interface IAiCVInfo {
  education: IEducation[];
  languages: ILanguage[];
  tagLine: string;
  workExperience: IWorkExperience[];
  extraWorkExperience: IWorkExperience[];
}

// Definición de la estructura para el usuario
export interface IUser {
  id: string;
  aiCVInfo: IAiCVInfo;
}

// Definición de la estructura de la query
export interface IUserQuery {
  user: IUser;
}

const ACTIVE_CAREER = gql`
  query userActiveCareer($getUserActiveCareerInput: GetUserActiveCareerInput!) {
    userActiveCareer(getUserActiveCareerInput: $getUserActiveCareerInput) {
      id
      name
      description
      id
      completionPercentage
      items {
        userItemPath {
          isAvailable
          mustBeHere
          name
          completed
          color
          id
          description
          multimedias {
            id
          }
          maturity {
            name
          }
          dimensions {
            name
          }
        }
        childrens {
          userItemPath {
            isAvailable
            mustBeHere
            name
            completed
            color
            id
            description
            multimedias {
              id
            }
            maturity {
              name
            }
            dimensions {
              name
            }
          }
          childrens {
            userItemPath {
              isAvailable
              mustBeHere
              name
              completed
              color
              id
              description
              multimedias {
                id
              }
              maturity {
                name
              }
              dimensions {
                name
              }
            }
            childrens {
              userItemPath {
                isAvailable
                mustBeHere
                name
                completed
                color
                id
                description
                multimedias {
                  id
                }
                maturity {
                  name
                }
                dimensions {
                  name
                }
              }
              childrens {
                userItemPath {
                  isAvailable
                  mustBeHere
                  name
                  completed
                  color
                  id
                  description
                  multimedias {
                    id
                  }
                  maturity {
                    name
                  }
                  dimensions {
                    name
                  }
                }
                childrens {
                  userItemPath {
                    isAvailable
                    mustBeHere
                    name
                    completed
                    color
                    id
                    description
                    multimedias {
                      id
                    }
                    maturity {
                      name
                    }
                    dimensions {
                      name
                    }
                  }
                  childrens {
                    userItemPath {
                      isAvailable
                      mustBeHere
                      name
                      completed
                      color
                      id
                      description
                      multimedias {
                        id
                      }
                      maturity {
                        name
                      }
                      dimensions {
                        name
                      }
                    }
                    childrens {
                      userItemPath {
                        isAvailable
                        mustBeHere
                        name
                        completed
                        color
                        id
                        description
                        multimedias {
                          id
                        }
                        maturity {
                          name
                        }
                        dimensions {
                          name
                        }
                      }
                      childrens {
                        userItemPath {
                          isAvailable
                          mustBeHere
                          name
                          completed
                          color
                          id
                          description
                          multimedias {
                            id
                          }
                          maturity {
                            name
                          }
                          dimensions {
                            name
                          }
                        }
                        childrens {
                          userItemPath {
                            isAvailable
                            mustBeHere
                            name
                            completed
                            color
                            id
                            description
                            multimedias {
                              id
                            }
                            maturity {
                              name
                            }
                            dimensions {
                              name
                            }
                          }
                          childrens {
                            userItemPath {
                              isAvailable
                              mustBeHere
                              name
                              completed
                              color
                              id
                              description
                              multimedias {
                                id
                              }
                              maturity {
                                name
                              }
                              dimensions {
                                name
                              }
                            }
                            childrens {
                              userItemPath {
                                isAvailable
                                mustBeHere
                                name
                                completed
                                color
                                id
                                description
                                multimedias {
                                  id
                                }
                                maturity {
                                  name
                                }
                                dimensions {
                                  name
                                }
                              }
                              childrens {
                                userItemPath {
                                  isAvailable
                                  mustBeHere
                                  name
                                  completed
                                  color
                                  id
                                  description
                                  multimedias {
                                    id
                                  }
                                  maturity {
                                    name
                                  }
                                  dimensions {
                                    name
                                  }
                                }
                                childrens {
                                  userItemPath {
                                    isAvailable
                                    mustBeHere
                                    name
                                    completed
                                    color
                                    id
                                    description
                                    multimedias {
                                      id
                                    }
                                    maturity {
                                      name
                                    }
                                    dimensions {
                                      name
                                    }
                                  }
                                  childrens {
                                    userItemPath {
                                      isAvailable
                                      mustBeHere
                                      name
                                      completed
                                      color
                                      id
                                      description
                                      multimedias {
                                        id
                                      }
                                      maturity {
                                        name
                                      }
                                      dimensions {
                                        name
                                      }
                                    }
                                    childrens {
                                      userItemPath {
                                        isAvailable
                                        mustBeHere
                                        name
                                        completed
                                        color
                                        id
                                        description
                                        multimedias {
                                          id
                                        }
                                        maturity {
                                          name
                                        }
                                        dimensions {
                                          name
                                        }
                                      }
                                      childrens {
                                        userItemPath {
                                          isAvailable
                                          mustBeHere
                                          name
                                          completed
                                          color
                                          id
                                          description
                                          multimedias {
                                            id
                                          }
                                          maturity {
                                            name
                                          }
                                          dimensions {
                                            name
                                          }
                                        }
                                        childrens {
                                          userItemPath {
                                            isAvailable
                                            mustBeHere
                                            name
                                            completed
                                            color
                                            id
                                            description
                                            multimedias {
                                              id
                                            }
                                            maturity {
                                              name
                                            }
                                            dimensions {
                                              name
                                            }
                                          }
                                          childrens {
                                            userItemPath {
                                              isAvailable
                                              mustBeHere
                                              name
                                              completed
                                              color
                                              id
                                              description
                                              multimedias {
                                                id
                                              }
                                              maturity {
                                                name
                                              }
                                              dimensions {
                                                name
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const LOGIN = gql`
  mutation Login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      accessToken
      refreshToken
    }
  }
`;

const LOGIN_WITH_TOKEN = gql`
  mutation LoginWithToken($token: String!) {
    loginWithToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;

const GET_USER = gql`
  query GetMyUser {
    user {
      id
      country
      profileImage
      name
      lastname
      email
      lang
      vendorList {
        id
        name
      }
    }
  }
`;

const GET_FULL_USER = gql`
  query user {
    user {
      id
      isi
      cvUrl
      birthday
      country
      username
      coverImage
      profileImage
      coinsAdmin
      jobyfineAdmin
      jobyfineRequester
      jobyfineRecruiter
      anonymousBoxAdmin
      name
      lastname
      email
      shortBio
      selectedVendorId
      systemRole
      twitter
      linkedin
      instagram
      github
      lang
      coins
      englishLevel
      hrManager
      coinsAdmin
      activeSince
      adExternalId
      availableRecognitions
      activeCareer {
        id
        completionPercentage
        name
      }
      role {
        name
        avatar
      }
      vendorList {
        id
        name
      }
      teams {
        id
        name
      }
      lastAppreciation {
        appreciation {
          name
        }
        userMaturityLevelByDimension {
          dimensionName
          maturityname
          percent
        }
      }
      skills {
        id
        name
      }
      selectedVendor {
        id
        name
        enableCareerPlan
        enableJobyfine
        enableBukIntegration
        enableCoins
        enableCareerPlan
        enableAdSync
        enableChallenges
        enableAnonymousBox
        enableActionPlans
        enableFlokzu
        enableExplore
        enableMicrosoftLogin
        enableForms
        enableVacations
        enableForms
        enableBirthdayAndAnniversary
        enableGoals
        enableCorporateValue
      }
      endorsements {
        id
        comment
        endorserUser {
          id
          name
        }
        skill {
          id
          name
        }
      }
      aiCVInfo {
        education {
          dateEnd
          dateStart
          description
          title
        }
        languages {
          level
          name
        }
        tagLine
        workExperience {
          date
          description
          title
          company
        }
        extraWorkExperience {
          date
          description
          title
          company
        }
      }
    }
  }
`;

const GET_USER_AI_CV_INFO = gql`
  query user {
    user {
      id
      aiCVInfo {
        education {
          dateEnd
          dateStart
          description
          title
        }
        languages {
          level
          name
        }
        tagLine
        workExperience {
          date
          description
          title
          company
        }
        extraWorkExperience {
          date
          description
          title
          company
        }
      }
    }
  }
`;

const LOGIN_ACTIVE_DIRECTORY = gql`
  mutation loginWithAzureActiveDirectory(
    $activeDirectoryLogInInput: SocialNetworkLogInInput!
  ) {
    loginWithAzureActiveDirectory(
      activeDirectoryLogInInput: $activeDirectoryLogInInput
    ) {
      accessToken
      refreshToken
    }
  }
`;

const GET_ACTIVE_USER = gql`
  query activeUsers {
    activeUsers {
      id
      name
      lastname
    }
  }
`;

const SYNC_ACTIVE_DIRECTORY_USERS = gql`
  query syncADUsers {
    syncADUsers
  }
`;

export interface IUserByEmail {
  email: string;
}

export interface IUserByEmailIDVar {
  getUserByEmail: IUser;
}

export interface IVendorUserListInput {
  team?: string;
  searchText?: string;
  limit?: number;
  offset?: number;
  roles?: string;
  systemRoles?: string;
}

export interface IVendorUserListResponse {
  vendorUserList: IUser[];
}

const GET_VENDOR_USER_LIST = gql`
  query vendorUserList($vendorUserListInput: VendorUserListInput!) {
    vendorUserList(vendorUserListInput: $vendorUserListInput) {
      id
      profileImage
      name
      lastname
      email
      country
      leader
      systemRoles {
        systemRole
      }
      role {
        id
        name
      }
      systemRole
      vendorList {
        id
        name
      }
      selectedVendorId
      selectedVendor {
        name
      }
      activeCareer {
        id
        completionPercentage
        name
      }
      dailyQuestionTasks
    }
  }
`;

const GET_VENDOR_USER_LIST_LIGHT = gql`
  query vendorUserList($vendorUserListInput: VendorUserListInput!) {
    vendorUserList(vendorUserListInput: $vendorUserListInput) {
      id
      profileImage
      name
      lastname
      email
      country
      role {
        id
        name
      }
    }
  }
`;

const USER_TO_MENTION = gql`
  query vendorUserList($vendorUserListInput: VendorUserListInput!) {
    vendorUserList(vendorUserListInput: $vendorUserListInput) {
      id
      name
      lastname
    }
  }
`;

const GET_VENDOR_USER_LIST_COIN_SCORE = gql`
  query vendorUserList($vendorUserListInput: VendorUserListInput!) {
    vendorUserList(vendorUserListInput: $vendorUserListInput) {
      id
      profileImage
      name
      lastname
      trainer
      coinsHistoricallyReceived
    }
  }
`;

export interface IVendorUserLeaderListResponse {
  vendorUserLeaderList: IUser[];
}

const GET_VENDOR_USER_LEADER_LIST = gql`
  query vendorUserLeaderList {
    vendorUserLeaderList {
      id
      name
      lastname
      email
    }
  }
`;

export interface IUserInvitationListResponse {
  userInvitations: IUserInvitation[];
}

const GET_USER_INVITATIONS_LIST = gql`
  query userInvitations {
    userInvitations {
      id
      createdAt
      email
      role {
        name
      }
      systemRole
      active
    }
  }
`;

const GET_MY_SPACE = gql`
  query mySpace {
    mySpace {
      appreciations
      pendingAppreciations
      answeredAppreciations
      onTheWayActionPlans
      achieveActionPlans
      unAchieveActionPlans
      achievements
      completedPaths
      completedCareers
      careers
      experience
      level
      messages
      teams
      lastAppreciation {
        appreciation {
          name
        }
        userMaturityLevelByDimension {
          dimensionId
          dimensionName
          maturityId
          maturityname
          percent
        }
      }
      userCertifications {
        id
        name
        expiracyDate
        url
        certificationUrl
        user
        deleted
        type
        emitDate
        certificationType
      }
      goal {
        id
        name
        description
        completed
        createdAt
        endDate
        responsible
        startDate
        updatedAt
        vendor
        completionPercentage
        tasks {
          id
          name
          type
          subType
          completedPercent
          createdAt
          updatedAt
          completed
          currency
          currentValue
          value
          minValue
          complianceType
          links
          refIds {
            id
            name
            type
            completed
          }
        }
      }
    }
  }
`;

export interface IUsersByDimensionAndTeamInput {
  team?: string;
  dimensions?: string[];
}

export interface IUsersByDimensionAndTeamResponse {
  usersByDimensionAndTeam: IUser[];
}

const GET_AFFECTED_USERS = gql`
  query usersByDimensionAndTeam(
    $usersByDimensionAndTeamInput: UsersByDimensionAndTeamInput!
  ) {
    usersByDimensionAndTeam(
      usersByDimensionAndTeamInput: $usersByDimensionAndTeamInput
    ) {
      id
      name
      lastname
      profileImage
    }
  }
`;

export interface IUserOnboardingRes {
  userOnboardingScheme: IUserOnboarding;
}
const USER_ONBOARDING = gql`
  query userOnboardingScheme {
    userOnboardingScheme {
      id
      completedPercentage
      steps {
        code
        completed
        description
        mantainerUrl
        icon
        title
        description
      }
    }
  }
`;

export const USER_LEVEL = gql`
  query UserLevel {
    userLevel {
      experience
      level
      nextLevelExp
      comments
    }
  }
`;

export interface IVendorUserListPaginatedRes {
  users: IUser[];
  total: number;
}

export const GET_VENDOR_USER_LIST_PAGINATED = gql`
  query VendorUserListPaginated($vendorUserListInput: VendorUserListInput!) {
    vendorUserListPaginated(vendorUserListInput: $vendorUserListInput) {
      users {
        id
        createdAt
        jobyfineRequester
        jobyfineRecruiter
        name
        country
        lastname
        birthday
        profileImage
        bukExternalJobName
        seniority
        seniorityLog {
          seniority
          date
        }
        email
        userFeedbacks
        countryCode
        nationality
        dailyQuestionTasks
        englishLevel
        englishLevelUpdates {
          newEnglishLevel
          oldEnglishLevel
          updatedAt
          userId
        }
        aiCVInfo {
          ofuscatedName
          tagLine
          workExperience {
            title
            description
            date
            company
          }
          education {
            title
            description
            dateStart
            dateEnd
          }
          languages {
            name
            level
          }
        }
        activeCareer {
          id
          name
        }
        role {
          id
          name
        }
        area {
          id
          name
          avatar
        }
        systemRole
        systemRoles {
          systemRole
        }
        active
        assigned
        assignable
        billability
        lastUsedIp
        adCleverId
        adExternalId
        adExternalJobTitle
        hrManager
        coinsAdmin
        anonymousBoxAdmin
        feedbackAdmin
      }
      total
    }
  }
`;

export const GET_VENDOR_EXPELLED_USERS = gql`
  query ExpelledUsers {
    expelledUsers {
      id
      createdAt
      jobyfineRequester
      jobyfineRecruiter
      name
      country
      lastname
      profileImage
      bukExternalJobName
      seniority
      seniorityLog {
        seniority
        date
      }
      email
      userFeedbacks
      countryCode
      nationality
      dailyQuestionTasks
      englishLevel
      englishLevelUpdates {
        newEnglishLevel
        oldEnglishLevel
        updatedAt
        userId
      }
      aiCVInfo {
        ofuscatedName
        tagLine
        workExperience {
          title
          description
          date
          company
        }
        education {
          title
          description
          dateStart
          dateEnd
        }
        languages {
          name
          level
        }
      }
      activeCareer {
        id
        name
      }
      role {
        id
        name
      }
      area {
        id
        name
        avatar
      }
      systemRole
      systemRoles {
        systemRole
      }
      active
      assigned
      assignable
      billability
      lastUsedIp
      adCleverId
      adExternalId
      adExternalJobTitle
      hrManager
      coinsAdmin
      anonymousBoxAdmin
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query UserProfile($userId: String!) {
    userProfile(userId: $userId) {
      id
      isi
      username
      country
      coverImage
      englishLevel
      profileImage
      name
      birthday
      lastname
      email
      lastActivityDate
      shortBio
      selectedVendorId
      systemRole
      seniority
      twitter
      linkedin
      instagram
      activeSince
      github
      lang
      coins
      adManager {
        id
        name
        lastname
        email
        profileImage
      }
      dailyQuestionTasks
      activeCareer {
        id
        completionPercentage
        name
      }
      role {
        name
        avatar
      }
      vendorList {
        id
        name
      }
      teams {
        id
        name
      }
      userRealTeams {
        id
        name
      }
      lastAppreciation {
        appreciation {
          name
        }
        userMaturityLevelByDimension {
          dimensionName
          maturityname
          percent
        }
      }
      skills {
        id
        name
      }
      endorsements {
        id
        comment
        endorserUser {
          id
          name
        }
        skill {
          id
          name
        }
      }
      userRank
      userLevel {
        experience
        nextLevelExp
        level
      }
      certifications {
        id
      }
      area {
        name
        avatar
      }
    }
  }
`;

export const PUBLIC_PROFILE = gql`
  query publicProfile($userId: String!) {
    publicProfile(userId: $userId) {
      id
      name
      lastname
      email
      shortBio
      coverImage
      profileImage
      linkedin
      github
      instagram
      dailyQuestionTasks
      username
      englishLevel
      birthday
      coins
      skills {
        id
        name
      }

      systemRoles {
        vendorId
        role
      }

      vendorList {
        id
        name
      }
    }
  }
`;

export interface UserVendorInfoRes {
  userVendorInfo: {
    lastAppreciation: IAppreciationDistribute;
    role: IRole;
    seniority?: EUserSeniority;
    englishLevel?: string;
  };
}

const USER_VENDOR_INFO = gql`
  query UserVendorInfo($userId: String!, $vendorId: String!) {
    userVendorInfo(userId: $userId, vendorId: $vendorId) {
      role {
        id
        name
        avatar
      }
      seniority
      englishLevel
      lastAppreciation {
        id
        appreciation {
          name
          id
        }
        userMaturityLevelByDimension {
          dimensionName
          maturityname
          percent
        }
        userGeneralMaturityLevel
      }
    }
  }
`;

export const USER_REPORT = gql`
  query Query($type: String!) {
    createUserReportXLS(type: $type)
  }
`;

export class IGetUserDailyLogInput {
  user: string;

  skip: number;
}

const GET_USER_DAILY_LOG = gql`
  query userDailyLog($getUserDailyLogInput: GetUserDailyLogInput!) {
    userDailyLog(getUserDailyLogInput: $getUserDailyLogInput) {
      date
      questions {
        question
        isCorrect
        options
      }
    }
  }
`;

export class IGetDailyQuestionDateDetailsInput {
  date: string;
}

const GET_DAILY_QUESTION_DATE_DETAILS = gql`
  query dailyQuestionDateDetails(
    $getDailyQuestionDateDetailsInput: GetDailyQuestionDateDetailsInput!
  ) {
    dailyQuestionDateDetails(
      getDailyQuestionDateDetailsInput: $getDailyQuestionDateDetailsInput
    ) {
      createdAt
      updatedAt
      user {
        id
        name
        profileImage
        email
      }
      date
      answered
      points
      role
      vendor
      sendedQuestions
      questions
      validating
    }
  }
`;

export const GET_USER_CERTIFICATIONS = gql`
  query userCertifications($userId: String!) {
    userCertifications(userId: $userId) {
      name
      expiracyDate
      url
      certificationUrl
      deleted
      type
      certificationType
      emitDate
    }
  }
`;

export interface IPaginationInput {
  limit?: number;
  offset: number;
}

export interface IUsersRequiredForProjectInput {
  skillsRequired: string[];
  skillsOptional: string[];
}

export interface IGetUsersRequiredForProjectResponse {
  users: IUser[];
  total: number;
}

export interface INationality {
  label: string;
  value: string;
}

export interface INationalities {
  filterNationality: INationality[];
}

export interface IGetVendorUsersBySkillAndSeniorityInput {
  skill: string;
  seniority: number;
  roles?: string[];
  englishLevel?: string;
  country?: string;
  users?: string[];
  gender?: GenderEnum;
  area?: string[];
}

export interface IUserCvInfo {}

export const GET_VENDOR_USERS_BY_SKILL_AND_SENIORITY = gql`
  query vendorUsersBySkillAndSeniority(
    $getVendorUsersBySkillAndSeniorityInput: GetVendorUsersBySkillAndSeniorityInput!
  ) {
    vendorUsersBySkillAndSeniority(
      getVendorUsersBySkillAndSeniorityInput: $getVendorUsersBySkillAndSeniorityInput
    ) {
      id
      name
      lastname
      email
      lastActivityDate
      profileImage
      countryCode
      role {
        id
        name
      }
    }
  }
`;

const USER_PROJECTS = gql`
  query ProjectsByUser($userId: String!) {
    projectsByUser(userId: $userId) {
      id
      externalId
      name
      startDate
      endDate
      realEndDate
      status
      projectFriendlyName
      vendor
      vendorClientName
      businessUnit
      asignationPercentage
    }
  }
`;

const GET_USER_VACATIONS = gql`
  query GetUsersVacations($getUserVacationsInput: GetUserVacationsInput!) {
    getUsersVacations(getUserVacationsInput: $getUserVacationsInput) {
      id
      name
      lastname
      profileImage
      email
    }
  }
`;

const GET_USERS_BIRTHDAY = gql`
  query UsersWithBirthday {
    usersWithBirthday {
      id
      name
      lastname
      email
      birthday
      isBirthday
      profileImage
      birthdayMessageId
    }
  }
`;

const GET_USERS_ANNIVERSARY = gql`
  query UsersWithAniversary {
    usersWithAniversary {
      id
      name
      lastname
      email
      activeSince
      isAnniversary
      profileImage
      anniversaryMessageId
    }
  }
`;

const GET_NATIONALITIES = gql`
  query FilterNationality {
    filterNationality {
      label
      value
    }
  }
`;

export default {
  GET_USER_PROFILE,
  GET_USER,
  GET_FULL_USER,
  LOGIN,
  LOGIN_ACTIVE_DIRECTORY,
  LOGIN_WITH_TOKEN,
  GET_ACTIVE_USER,
  SYNC_ACTIVE_DIRECTORY_USERS,
  GET_VENDOR_USER_LIST,
  GET_USER_INVITATIONS_LIST,
  GET_VENDOR_USER_LIST_PAGINATED,
  GET_NATIONALITIES,
  GET_VENDOR_USER_LEADER_LIST,
  ACTIVE_CAREER,
  GET_MY_SPACE,
  GET_AFFECTED_USERS,
  USER_ONBOARDING,
  USER_LEVEL,
  PUBLIC_PROFILE,
  USER_VENDOR_INFO,
  USER_REPORT,
  GET_USER_DAILY_LOG,
  GET_DAILY_QUESTION_DATE_DETAILS,
  GET_USER_CERTIFICATIONS,
  GET_VENDOR_USERS_BY_SKILL_AND_SENIORITY,
  USER_PROJECTS,
  GET_VENDOR_USER_LIST_LIGHT,
  GET_VENDOR_USER_LIST_COIN_SCORE,
  GET_USER_VACATIONS,
  GET_USERS_BIRTHDAY,
  GET_USERS_ANNIVERSARY,
  GET_USER_AI_CV_INFO,
  USER_TO_MENTION,
  GET_VENDOR_EXPELLED_USERS,
};
