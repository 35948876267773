import { useWindowSize } from "@react-hook/window-size";
import Icon from "components/Icon";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Tooltip, Whisper } from "rsuite";
import { useUserStore } from "stores/user/useUserStore";
import { guardCheckUserRole } from "../../../utils/guards";
import SVG from "../../SVG";
import { ISidebarMenu } from "./Sidebar";
import {
  IMenuListItem,
  ISubMenuLists,
  LAYOUT_STORED_SUBMENU,
  menuItemByPath,
} from "./utils";

interface ISubMenuStatic extends ISidebarMenu {
  lists: ISubMenuLists;
  selectedMenuItem: IMenuListItem;
  onItemClick: (item: IMenuListItem) => void;
  onBackMobile: () => void;
}

const SubMenuStatic = (props: ISubMenuStatic) => {
  const {
    lists,
    selectedMenuItem,
    onItemClick: onItemClickProp,
    status,
    onBackMobile,
  } = props;

  const router = useRouter();
  const { user } = useUserStore();

  const [windowX] = useWindowSize();
  const isMobile = windowX < 768;

  const [pEvents, setPEvents] = useState<"none" | "auto">(
    isMobile ? "auto" : "none"
  );

  const [active, setActive] = useState<string>("");

  useEffect(() => {
    setActive(
      menuItemByPath(Object.values(lists).flat(), router.route)?.name || ""
    );
  }, []);

  const onItemClick = (item: IMenuListItem) => {
    setActive(item.name);
    localStorage.setItem(LAYOUT_STORED_SUBMENU, JSON.stringify(item));
    if (onItemClickProp) onItemClickProp(item);
  };

  const onItemHover = (isEnter: boolean) => {
    if (!isMobile) setPEvents(isEnter ? "auto" : "none");
  };

  return (
    <div
      className="flex h-full w-full flex-col"
      style={{ pointerEvents: pEvents }}
    >
      {isMobile && (
        <div className="ml-6 mt-6">
          <Icon
            icon="arrow-left"
            onClick={onBackMobile}
            className="cursor-pointer"
          />
        </div>
      )}

      <div className="flex-1 flex-col overflow-y-auto overflow-x-visible py-5">
        {(lists[selectedMenuItem.name] || []).map((list) => (
          <div key={list.header}>
            {list.header && (
              <div className="flex h-3 w-full items-center py-5 pl-4 text-lg text-gray-400">
                {status === "opened" ? (
                  <span className="animate__animated animate__fadeIn">
                    {list.header}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}

            {list.items.map((item) => {
              if (!guardCheckUserRole(item.route?.roleGuards, user?.systemRole))
                return null;

              return (
                <Whisper
                  disabled={isMobile || status === "opened"}
                  trigger="hover"
                  placement="right"
                  controlId={`control-id-${item.name}`}
                  speaker={<Tooltip>{item.name}</Tooltip>}
                  key={item.name}
                >
                  <div
                    className={`relative flex h-16 w-full cursor-pointer items-center hover:bg-gray-100 dark:hover:bg-gray-700 ${
                      active === item.name ? "bg-gray-100 dark:bg-gray-700" : ""
                    }`}
                    style={{
                      width: status === "opened" ? "100%" : "20%",
                      pointerEvents: "auto",
                    }}
                    onClick={() => onItemClick(item)}
                    onMouseEnter={() => onItemHover(true)}
                    onMouseLeave={() => onItemHover(false)}
                  >
                    <div
                      className="flex items-center justify-center"
                      style={{ width: status === "opened" ? "20%" : "100%" }}
                    >
                      <SVG
                        key={item.name}
                        src={`/svg/${item.icon}.svg`}
                        width={30}
                        height={30}
                      />
                    </div>

                    {status === "opened" && (
                      <div className="animate__animated animate__fadeIn flex-1 pl-2">
                        {item.name}
                      </div>
                    )}
                  </div>
                </Whisper>
              );
            })}
          </div>
        ))}
      </div>

      <div className="flex justify-end p-5">
        {/* <Icon
          icon={isMenuOpen ? faArrowLeft : faArrowRight}
          onClick={() => setIsSubMenuOpen(status === "closed")}
          className="cursor-pointer"
        /> */}
      </div>
    </div>
  );
};

export default SubMenuStatic;
