import { IMenuList } from "components/Layout/ManagerProLayout/utils";
import { useIsScreenMd } from "hooks/useIsScreenMd";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { IRoute } from "routes/types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "shadcn/components/ui/accordion";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "shadcn/components/ui/dropdown-menu";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";

interface MenuDropdownItemProps {
  item: IMenuList;
  onRedirect: (path: string) => void;
  checkSubRoutesRoleGuard: (routes: IRoute[]) => boolean;
  evaluateSubRoutesIncludesPath: (pathname: string, item: IMenuList) => boolean;
  isHovered: boolean;
  onHover: () => void;
  setIsHoverItem: (value: boolean) => void;
}

export default function MenuDropdownItem(props: MenuDropdownItemProps) {
  const {
    item,
    onRedirect,
    checkSubRoutesRoleGuard,
    evaluateSubRoutesIncludesPath,
    isHovered,
    onHover,
    setIsHoverItem,
  } = props;
  const router = useRouter();
  const { isExpanded } = useDesktopSidebarStore();
  const [renderText, setRenderText] = useState(true);
  const [renderExpandedComponent, setRenderExpandedComponent] = useState(true);
  const [showDropdown, setShowDropdown] = useState(true);
  const isScreenMd = useIsScreenMd();

  const hasAccess = !checkSubRoutesRoleGuard(
    item?.children?.map((route) => route?.route) || []
  );

  useEffect(() => {
    if (!isExpanded && isScreenMd) {
      setTimeout(() => {
        setRenderText(false);
        setRenderExpandedComponent(false);
      }, 200);
    }
    if (isExpanded && isScreenMd) {
      setRenderText(true);
      setRenderExpandedComponent(true);
    }
    if (!isScreenMd) {
      setRenderText(true);
      setRenderExpandedComponent(true);
    }
  }, [isExpanded, isScreenMd]);

  return hasAccess ? (
    <>
      {renderExpandedComponent ? (
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1" className="w-full border-b-0">
            <AccordionTrigger
              className={`h-sidebar-button w-full py-0 pl-6 pr-3 font-normal hover:no-underline ${
                !evaluateSubRoutesIncludesPath(router.pathname, item) &&
                "hover:bg-gray-100 hover:dark:bg-slate-800"
              } ${
                evaluateSubRoutesIncludesPath(router.pathname, item) &&
                "active bg-gray-200 dark:bg-gray-800"
              }`}
            >
              <div className="flex h-full w-full items-center gap-4">
                <picture className="relative grid min-h-sidebar-icon min-w-sidebar-icon">
                  <Image
                    src={`/svg/menu/${item.icon}.svg`}
                    fill
                    sizes="w-sidebar-icon"
                    className="h-sidebar-icon w-sidebar-icon object-contain"
                    alt={item.name}
                    priority
                  />
                </picture>
                {renderText && (
                  <span className="block overflow-hidden truncate text-sidebar">
                    {item.name}
                  </span>
                )}
              </div>
            </AccordionTrigger>
            {item?.children?.map((route) =>
              route.route
                ? !checkSubRoutesRoleGuard([route.route]) && (
                    <AccordionContent
                      className="my-1 grid h-sidebar-dropdown-button w-full items-center gap-4 pb-0 pl-6 pr-4 text-left"
                      key={route.route.path}
                    >
                      <div
                        className={`flex h-full w-full cursor-pointer gap-4 rounded-2xl pl-4 ${
                          router.pathname !== route.route.path &&
                          "hover:bg-gray-100 hover:dark:bg-slate-800"
                        } ${
                          router.pathname === route.route.path
                            ? "active rounded-2xl bg-gray-200 dark:bg-gray-800"
                            : ""
                        }`}
                        onClick={() => onRedirect(route?.route?.path || "")}
                      >
                        <picture className="relative grid min-h-sidebar-dropdown-icon min-w-sidebar-dropdown-icon">
                          <Image
                            src={`/svg/menu/${route.icon}.svg`}
                            fill
                            sizes="w-sidebar-dropdown-icon"
                            className="h-sidebar-dropdown-icon w-sidebar-dropdown-icon object-contain"
                            alt={item.name}
                            priority
                          />
                        </picture>

                        <span className="grid h-full w-full items-center text-sidebar-submenu">
                          {route.name}
                        </span>
                        {route.beta && (
                          <span className="ml-2 rounded bg-blue-200 p-1 text-xs text-blue-800">
                            Beta
                          </span>
                        )}
                      </div>
                    </AccordionContent>
                  )
                : null
            )}
          </AccordionItem>
        </Accordion>
      ) : (
        <DropdownMenu open={isHovered && showDropdown} modal={false}>
          <DropdownMenuTrigger
            onMouseOver={() => {
              onHover(); // Llama a la primera función
              setIsHoverItem(false); // Llama a la segunda función
              setShowDropdown(true);
            }}
            className={`flex h-sidebar-button w-full items-center gap-4 pl-6 focus-visible:outline-none ${
              !evaluateSubRoutesIncludesPath(router.pathname, item) &&
              "hover:bg-gray-100 hover:dark:bg-slate-800"
            } ${
              evaluateSubRoutesIncludesPath(router.pathname, item) &&
              "active bg-gray-200 dark:bg-gray-800"
            }`}
          >
            <div className="flex h-full items-center">
              <picture className="relative grid min-h-sidebar-icon min-w-sidebar-icon">
                <Image
                  src={`/svg/menu/${item.icon}.svg`}
                  fill
                  sizes="w-sidebar-icon"
                  className="h-sidebar-icon w-sidebar-icon object-contain"
                  alt={item.name}
                  priority
                />
              </picture>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            id={`dropdown-item-${item.name}`}
            side="right"
            align="center"
            className="ml-1 bg-gray-50"
            onMouseLeave={() => setShowDropdown(false)}
          >
            <DropdownMenuLabel>{item.name}</DropdownMenuLabel>
            <DropdownMenuSeparator className="bg-neutral-200" />
            {item?.children?.map((route) =>
              route.route
                ? !checkSubRoutesRoleGuard([route.route]) && (
                    <DropdownMenuItem
                      className={`grid w-full items-center gap-4 py-1 text-left ${
                        router.pathname !== route.route.path
                          ? "hover:bg-gray-100 hover:dark:bg-slate-800"
                          : ""
                      } ${
                        router.pathname === route.route.path
                          ? "active rounded-sm bg-gray-200 dark:bg-gray-800"
                          : ""
                      }`}
                      key={route.route.path}
                    >
                      <div
                        className="flex h-[36px] w-full cursor-pointer gap-2"
                        onClick={() => onRedirect(route?.route?.path || "")}
                      >
                        <picture className="relative grid min-h-sidebar-dropdown-icon min-w-sidebar-dropdown-icon">
                          <Image
                            src={`/svg/menu/${route.icon}.svg`}
                            fill
                            sizes="25px"
                            className="h-[25px] w-[25px] object-contain"
                            alt={route.name}
                            priority
                          />
                        </picture>
                        <span className="grid h-full w-full items-center text-sidebar-submenu">
                          {route.name}
                        </span>
                        {route.beta && (
                          <span className="ml-2 rounded bg-blue-200 p-1 text-xs text-blue-800">
                            Beta
                          </span>
                        )}
                      </div>
                    </DropdownMenuItem>
                  )
                : null
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  ) : null;
}
