import { IMenuList } from "components/Layout/ManagerProLayout/utils";
import { useIsScreenMd } from "hooks/useIsScreenMd";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { IRoute } from "routes/types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "shadcn/components/ui/tooltip";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";

interface MenuItemProps {
  item: IMenuList;
  onClick: (path: string) => void;
  checkSubRoutesRoleGuard: (routes: IRoute[]) => boolean;
  setIsHoverItem: (value: boolean) => void;
}

export default function MenuItem(props: MenuItemProps) {
  const { item, onClick, checkSubRoutesRoleGuard, setIsHoverItem } = props;
  const router = useRouter();
  const { isExpanded } = useDesktopSidebarStore();
  const [renderText, setRenderText] = useState(true);
  const isScreenMd = useIsScreenMd();

  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        setRenderText(false);
      }, 200);
    } else {
      setRenderText(true);
    }
  }, [isExpanded]);

  const handleMouseEnter = () => {
    setIsHoverItem(true); // Actualizar estado a 'close' al salir del hover
  };

  return item.route && !checkSubRoutesRoleGuard([item.route]) ? (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger
          className={`flex h-sidebar-button w-full items-center gap-4 pl-6 ${router.pathname !== item.route.path && "hover:bg-gray-100 hover:dark:bg-slate-800"} ${router.pathname === item.route.path && "active bg-gray-200 dark:bg-gray-800"}`}
          onClick={() => onClick(item.route.path)}
          onMouseOver={handleMouseEnter}
        >
          <>
            <picture className="relative grid min-h-sidebar-icon min-w-sidebar-icon">
              <Image
                src={`/svg/menu/${item.icon}.svg`}
                fill
                sizes="w-sidebar-icon"
                className="h-sidebar-icon w-sidebar-icon object-contain"
                alt={item.name}
                priority
              />
            </picture>

            {renderText && (
              <span className="block overflow-hidden truncate text-sidebar">
                {item.name}
              </span>
            )}
          </>
        </TooltipTrigger>
        {!isExpanded && isScreenMd && (
          <TooltipContent side="right">
            <p> {item.name}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  ) : null;
}
