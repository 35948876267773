import { gql } from "@apollo/client";
import { ICorporateValues } from "types/CorporateValue.type";
import { IUser } from "types/User.types";

export enum TimeRangeEnum {
  CURRENT_MONTH = "CURRENT_MONTH",
  HISTORIC = "HISTORIC",
}

export interface ICorporateValueResponse {
  getCorporateValuesByVendor: ICorporateValues[];
}

export const GET_CORPORATE_VALUES_BY_VENDOR = gql`
  query getCorporateValuesByVendor {
    getCorporateValuesByVendor {
      description
      avatar
      title
      vendorId
      id
      timesSent
    }
  }
`;

export interface ICorporateValueRankingByVendor {
  corporateValueRankingByVendor?: ICorporateValueRanking[];
}

export interface ICorporateValueRanking {
  user: IUser;
  recognitions: number;
}

export const GET_RANKING_CORPORATE_VALUES = gql`
  query CorporateValueRankingByVendor {
    corporateValueRankingByVendor {
      recognitions
      user {
        id
        name
        lastname
        email
        birthday
        isBirthday
        profileImage
        birthdayMessageId
      }
    }
  }
`;

export interface IVendorUserList {
  id: string;
  name: string;
  lastname?: string;
  email: string;
  profileImage?: string;
  role: Role;
  fullName?: string;
}

export interface Role {
  id: string;
  name: string;
}

export interface IVendorUserListResponse {
  vendorUsersListWithoutUserLogin: IVendorUserList[];
}

export const GET_SIMPLE_VENDOR_USER_LIST = gql`
  query vendorUsersListWithoutUserLogin {
    vendorUsersListWithoutUserLogin {
      id
      name
      lastname
      email
      profileImage
      role {
        id
        name
      }
    }
  }
`;

export const REPORT_CORPORATE_VALUE = gql`
  query Query {
    corporateValueReport
  }
`;

export interface ICorporateValueRanking {
  corporateValueRanking: ICorporateValueRankingUsers[];
}

export interface ICorporateValueRankingUsers {
  avatar: string;
  name: string;
  lastName?: string;
  recognitions: number;
  email: string;
  id: string;
}

export const CORPORATE_VALUE_RANKING = gql`
  query CorporateValueRanking(
    $corporateValueRankingId: String!
    $type: String!
  ) {
    corporateValueRanking(id: $corporateValueRankingId, type: $type) {
      avatar
      email
      name
      lastName
      recognitions
      id
    }
  }
`;
