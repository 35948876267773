import {
  AFTER_LOGIN_REDIRECT,
  NO_ORGANIZATION_REDIRECT,
  SITE_CREATION_HOSTS,
  USER_LANG,
  isBrowser,
} from "@constants/global";
import GroowLoader from "components/Loader/GroowLoader";
import setLanguage from "next-translate/setLanguage";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "routes/routes";
import useAppStore from "stores/useAppStore";
import { useUserStore } from "stores/user/useUserStore";

const SiteLoader = ({ children }) => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { lang } = useTranslation();

  const router = useRouter();

  const { user, onboardingData } = useUserStore();
  const { isInitialized } = useAppStore();

  const isPublicRoute = useCallback(
    () =>
      Object.values(PUBLIC_ROUTES)
        .map((route) => route.path)
        .includes(router.pathname),
    [router.pathname]
  );

  // ! workarround *
  // TODO: check if this cames from app.groowcity.com or app.gwoocity.com
  // * app.groowcity.com logic here...
  // * if host is app.groowcity.com we only can go to /new-organization or /vendor-selector
  if (isBrowser && SITE_CREATION_HOSTS.includes(window.location.host)) {
    if (
      router.pathname !== "/new-organization" &&
      router.pathname !== "/vendor-selector"
    ) {
      router.push("/new-organization").then(() => setShowLoader(false));
    }
  }

  useEffect(() => {
    if (!isInitialized) return;
    if (user) {
      // * load language from the user
      if (user?.lang !== lang) {
        localStorage.setItem(USER_LANG, user?.lang ?? "en");
        setLanguage(user?.lang ?? "en", false);
      }

      if (user.vendorList?.length > 0) {
        if (
          router.pathname === "/" ||
          router.pathname === "/no-organization" ||
          router.pathname === "/login"
        ) {
          if (router.query?.redirect) {
            const rawQuery = { ...router.query };

            delete rawQuery.redirect;

            router
              .push({
                pathname: router.query.redirect as string,
                query: rawQuery,
              })
              .then(() => setShowLoader(false));
          } else if (onboardingData?.completedPercentage !== 100) {
            router
              .push(PRIVATE_ROUTES.appOnboarding.path) // * redirect to onboarding
              .then(() => setShowLoader(false));
          } else {
            router.push(AFTER_LOGIN_REDIRECT).then(() => setShowLoader(false));
          }
        } else {
          setShowLoader(false);
        }
      } else if (
        router.pathname !== "/no-organization" &&
        router.pathname !== "/new-organization" &&
        router.pathname !== "/inv"
      ) {
        router.push(NO_ORGANIZATION_REDIRECT).then(() => setShowLoader(false));
      } else {
        setShowLoader(false);
      }
    }
    if (!user) {
      if (!isPublicRoute()) {
        // * check if is firstLogin if so dont
        if (router?.asPath.includes("firstLogin")) {
          router
            .push({
              pathname: PUBLIC_ROUTES.login.path,
              query: {
                loginsource: router.asPath,
              },
            })
            .then(() => setShowLoader(false));
        } else {
          // if router.asPath is / then redirect to login

          if (router.asPath === "/") {
            router
              .push(PUBLIC_ROUTES.login.path)
              .then(() => setShowLoader(false));
          } else {
            router
              .push({
                pathname: PUBLIC_ROUTES.login.path,
                query: {
                  redirect: router.asPath,
                },
              })
              .then(() => setShowLoader(false));
          }
        }
      } else {
        setShowLoader(false);
      }
    }
  }, [user, router.pathname, isInitialized]);

  // * in order to work with the SEO of the initiative page we need to exclude from site loader
  if (
    showLoader &&
    router.pathname !== PUBLIC_ROUTES.activityPage.path &&
    router.pathname !== PUBLIC_ROUTES.jobyfine.path &&
    router.pathname !== PUBLIC_ROUTES.jobs.path &&
    router.pathname !== PRIVATE_ROUTES.thread.path
  ) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-white dark:bg-black">
        <GroowLoader size={150} />
      </div>
    );
  }

  return <>{children}</>;
};

export default SiteLoader;
