import { ESystemRoles } from "@constants/SystemRoles";
import { ELayout } from "@constants/layout";
import CleanLayout from "components/Layout/CleanLayout";
import GeneralLayout from "components/Layout/GeneralLayout";
import LandingLayout from "components/Layout/LandingLayout";
import ManagerProLayout from "components/Layout/ManagerProLayout/ManagerProLayout";
import PortalLayout from "components/Layout/PortalLayout";
import PublicWithHeader from "components/Layout/PublicWithHeader";
import SidebarLayout from "components/Layout/SidebarLayout";
import React from "react";

interface IRouteLayout {
  layout: ELayout | undefined;
  children: React.ReactNode;
}

const LAYOUTS = {
  [ELayout.GENERAL]: GeneralLayout,
  [ELayout.LANDING]: LandingLayout,
  [ELayout.CLEAN]: CleanLayout,
  [ELayout.SIDEBAR]: SidebarLayout,
  [ELayout.PROMANAGER]: ManagerProLayout,
  [ELayout.PORTAL]: PortalLayout,
  [ELayout.PUBLIC_WITH_HEADER]: PublicWithHeader,
};

export const RouteLayout: React.FC<IRouteLayout> = (props) => {
  const { children } = props;
  const { layout = ELayout.GENERAL } = props;
  const Layout = LAYOUTS[layout];

  return <Layout>{children}</Layout>;
};

export interface IRoute {
  path: string;
  layout?: ELayout;
  roleGuards?: ESystemRoles[];
  jobyfineRecruiter?: boolean;
  jobyfineRequester?: boolean;
}
