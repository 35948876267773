import { ELayout } from "@constants/layout";
import { ESystemRoles } from "@constants/SystemRoles";

export const DEEFAULT_ROUTER_PROFILE = "/app/profile";
export const PRIVATE_ROUTES = {
  home: {
    path: "/app/home",
    layout: ELayout.PROMANAGER,
  },
  appOnboarding: {
    path: "/app/onboarding",
    layout: ELayout.PROMANAGER,
  },
  practice: {
    path: "/practice",
  },
  profile: {
    path: DEEFAULT_ROUTER_PROFILE,
    layout: ELayout.PROMANAGER,
  },
  userMultimedias: {
    path: "/app/multimedias/user-multimedias",
    layout: ELayout.PROMANAGER,
  },
  skillsRequirements: {
    path: "/app/project/skills-requirements",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
    jobyfineRecruiter: true,
    jobyfineRequester: true,
  },
  anonybox: {
    path: "/app/anonybox",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  appreciationList: {
    path: "/app/maintainers/appreciation",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  appreciationDetails: {
    path: "/app/maintainers/appreciation/[detail]",
    layout: ELayout.PROMANAGER,
  },
  pathsUser: {
    path: "/app/paths-user",
    layout: ELayout.PROMANAGER,
  },
  radar: {
    path: "/app/radar",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  showCourse: {
    path: "/app/paths-user/show/[id]",
    layout: ELayout.PROMANAGER,
  },
  showCourseTable: {
    path: "/app/paths-user/show-as-table/[id]",
    layout: ELayout.PROMANAGER,
  },
  myAppreciations: {
    path: "/app/appreciation-user",
    layout: ELayout.PROMANAGER,
  },
  anonymous: {
    path: "/app/anonymous-box",
    layout: ELayout.PROMANAGER,
  },
  appreciationAnswer: {
    path: "/app/appreciation-user/[id]",
    layout: ELayout.PROMANAGER,
  },
  appreciationCompleted: {
    path: "/app/appreciation-user/completed/[id]",
    layout: ELayout.PROMANAGER,
  },
  roleMaintainer: {
    path: "/app/maintainers/role",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  divisionMaintainer: {
    path: "/app/maintainers/division",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  areaMaintainer: {
    path: "/app/maintainers/area",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  corporateValuesMaintainer: {
    path: "/app/maintainers/corporate-values",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  dimensionMaintainer: {
    path: "/app/maintainers/dimension",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  dimensionEditMaintainer: {
    path: "/app/maintainers/dimension/edit/[id]",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  userMaintainer: {
    path: "/app/maintainers/user",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  teamMaintainer: {
    path: "/app/maintainers/team",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  coursesMaintainer: {
    path: "/app/maintainers/careers",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  newCourse: {
    path: "/app/maintainers/careers/new-course",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  editCourse: {
    path: "/app/maintainers/careers/edit/[id]",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  multimediaMaintainer: {
    path: "/app/maintainers/multimedia",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  itemPathMantainers: {
    path: "/app/maintainers/itempath",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  editItemPath: {
    path: "/app/maintainers/itempath/edit/[id]",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  teamDetails: {
    path: "/app/maintainers/team/details/[id]",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  questionMantainers: {
    path: "/app/maintainers/question",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  noOrganization: {
    path: "/no-organization",
    layout: ELayout.LANDING,
  },
  myCareer: {
    path: "/app/my-career",
    layout: ELayout.PROMANAGER,
  },
  actionPlans: {
    path: "/app/maintainers/actionplan",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  activities: {
    path: "/app/maintainers/activities",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  publicAppreciation: {
    path: "/app/maintainers/appreciation-public",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
    jobyfineRecruiter: true,
    jobyfineRequester: false,
  },
  teamsLeader: {
    path: "/app/teams-leader",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  teamsLeaderDetails: {
    path: "/app/teams-leader/[id]",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  releasesLog: {
    path: "/releases/changelog",
    layout: ELayout.GENERAL,
    roleGuards: [],
  },
  dashboard: {
    path: "/app/dashboard",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  organizationDashboard: {
    path: "/app/dashboard/organization",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  configMantainer: {
    path: "/app/config",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  coinsProduct: {
    path: "/app/coins/coins-product",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  coinsProductCategory: {
    path: "/app/coins/coins-product-category",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  coinsCategory: {
    path: "/app/coins/coins-category",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  coinMarketplace: {
    path: "/app/coins/coin-marketplace",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER, ESystemRoles.USER],
  },
  coinBasket: {
    path: "/app/coins/coin-basket",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER, ESystemRoles.USER],
  },
  coinsExchange: {
    path: "/app/coins/coin-exchange",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER, ESystemRoles.USER],
  },
  coinsController: {
    path: "/app/coins/coin-controller",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },

  coinsTopUsers: {
    path: "/app/coins/top-users",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.USER, ESystemRoles.LEADER],
  },
  jobyfineRequest: {
    path: "/app/jobyfine/jobyfine-request",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
    jobyfineRecruiter: true,
    jobyfineRequester: true,
  },
  jobyfineApplicants: {
    path: "/app/jobyfine/jobyfine-applicants",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
    jobyfineRecruiter: true,
    jobyfineRequester: false,
  },
  jobyfineOffers: {
    path: "/app/jobyfine/jobyfine-offers",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
    jobyfineRecruiter: true,
    jobyfineRequester: false,
  },
  jobyfineProcess: {
    path: "/app/jobyfine/jobyfine-process",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
    jobyfineRecruiter: true,
    jobyfineRequester: false,
  },
  vacationUser: {
    path: "/app/vacation",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER, ESystemRoles.USER],
  },

  /* ISI */
  vacationManagement: {
    path: "/app/vacation/config",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  isiFeedback: {
    path: "/app/superadmin/feedback",
    layout: ELayout.PROMANAGER,
    roleGuards: [],
  },
  isiSkills: {
    path: "/app/superadmin/skills",
    layout: ELayout.PROMANAGER,
    roleGuards: [],
  },
  dailyQuestions: {
    path: "/app/daily-question",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  tinder: {
    path: "/tinder",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN],
  },
  explore: {
    layout: ELayout.PROMANAGER,
    path: "/app/explore",
  },
  thread: {
    layout: ELayout.PROMANAGER,
    path: "/app/thread/[id]",
  },
  challenge: {
    layout: ELayout.PROMANAGER,
    path: "/app/maintainers/challenge",
    roleGuards: [ESystemRoles.ADMIN],
  },
  publicChallenge: {
    layout: ELayout.PROMANAGER,
    path: "/app/superadmin/public-challenge",
  },
  userChallenge: {
    layout: ELayout.CLEAN,
    path: "/challenge/[id]",
    roleGuards: [ESystemRoles.ADMIN],
  },
  questionsIndex: {
    layout: ELayout.PROMANAGER,
    path: "/questions-index",
  },
  userQuestionManager: {
    layout: ELayout.PROMANAGER,
    path: "/app/user-questions-manager",
  },
  userActionPlans: {
    layout: ELayout.PROMANAGER,
    path: "/app/action-plans",
  },
  forms: {
    layout: ELayout.PROMANAGER,
    path: "/app/maintainers/forms",
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
  MyForms: {
    path: "/app/my-forms",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER, ESystemRoles.USER],
  },
  distributions: {
    path: "/app/maintainers/appreciation/distributions/[id]",
    layout: ELayout.PROMANAGER,
    roleGuards: [ESystemRoles.ADMIN, ESystemRoles.LEADER],
  },
};

export const PUBLIC_ROUTES = {
  404: { path: "/404", layout: ELayout.CLEAN },
  401: { path: "/401", layout: ELayout.CLEAN },
  500: { path: "/500", layout: ELayout.CLEAN },
  login: { path: "/login" },
  logout: { path: "/logout" },
  register: { path: "/register" },
  linkedin: { path: "/linkedin" },
  recoveryPasswordSendEmail: { path: "/recovery-password-send" },
  recoveryPasswordSetNewPass: { path: "/recovery-password-set" },
  activityPage: {
    path: "/activity/[slug]",
    layout: ELayout.GENERAL,
  },
  pradar: {
    path: "/pradar/[id]",
    layout: ELayout.CLEAN,
  },
  releasesLog: {
    path: "/releases/changelog",
    layout: ELayout.GENERAL,
  },
  publicAppreciation: {
    path: "/public-appreciation/[token]",
    layout: ELayout.PUBLIC_WITH_HEADER,
  },
  newOrganization: {
    path: "/new-organization",
    layout: ELayout.GENERAL,
  },
  invitation: {
    path: "/inv",
  },
  publicProfile: {
    path: "/profile/[id]",
    layout: ELayout.GENERAL,
  },
  jobyfine: {
    path: "/portal",
    layout: ELayout.PORTAL,
  },
  jobs: {
    path: "/jobs/[id]",
    layout: ELayout.PORTAL,
  },
  vendorSelector: {
    path: "/vendor-selector",
    layout: ELayout.CLEAN,
  },
  jobyfinePage: {
    path: "/jobyfine/[id]",
  },
  publicFormPage: {
    path: "/app/public/form/[id]",
  },
};
