export enum ELayout {
  "MANAGER" = "MANAGER",
  "LANDING" = "LANDING",
  "GENERAL" = "GENERAL",
  "CLEAN" = "CLEAN",
  "TWO_MENUS" = "TWO_MENUS",
  "SIDEBAR" = "SIDEBAR",
  "PROMANAGER" = "PROMANAGER",
  "PORTAL" = "PORTAL",
  "PUBLIC_WITH_HEADER" = "PUBLIC_WITH_HEADER",
}
