import { LOGO_DARK, LOGO_LIGHT } from "@constants/global";
import { useTheme } from "next-themes";
import Image from "next/legacy/image";

const PublicNavBar = () => {
  const { theme } = useTheme();
  return (
    <>
      <div
        className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white"
        style={{ height: 4, minHeight: 4 }}
      />

      <nav className="relative z-7 flex w-full flex-wrap items-center justify-between bg-gray-50 pr-3 pt-3 dark:bg-gray-900 md:px-6 md:pb-4">
        <div className="container mx-4">
          <Image
            src={theme === "dark" ? LOGO_DARK : LOGO_LIGHT}
            width={180}
            height={60}
            alt="Logo"
          />
        </div>
      </nav>
    </>
  );
};

export default PublicNavBar;
